import { ArrowBackIos } from "@mui/icons-material"
import { PdfWorker } from "./worker/PdfWorker";

export const Iframe = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('url');
    
    return (<div style={{height: '100vh', width: '100vw'}}>
                <div style={{display: 'flex', height: 50, alignItems: 'center', paddingLeft: 24, paddingTop: 24}}>
                    <div onClick={() => window.history.go(-1)}><ArrowBackIos></ArrowBackIos></div>
                </div>
                <PdfWorker path={myParam} />
             </div>)
}