import React, { useEffect, useRef, useState } from 'react';
import Card from '../components/Card';
import { Add, DashboardCustomize, ExpandMore, FilterList, Language, Newspaper } from '@mui/icons-material';
import CustomButton from '../components/Button';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import CardCertificati from '../components/CardCertificati';
import { InputLabel, Fab, Menu, MenuItem, Modal, Box, Typography, TextField, Button, Select, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ServerConfig } from '../connectors/Config';
import moment from 'moment';
import { config, S3} from 'aws-sdk';
import { Buffer } from "buffer";
import { AuthHelper } from '../helpers/AuthHelper';
import LoadingButton from '@mui/lab/LoadingButton';


const style={
    container: {
        paddingLeft: 28, 
        paddingRight: 26,
    }
}
const fileConverterBase64 = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            res(reader.result)
        }
        reader.onerror = (err) => {
            rej(err)
        }
    })
}
const Dashboard = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
  
    const handleChange = async (e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, region: 'eu-west-1', credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.certificate.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            // const res = await s3.putObject(params).promise();
            setCertificate({...certificate, pdf: `https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}.pdf`})
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };

    const [certificate, setCertificate] = useState({
        number: "",
        released: "",
        expiry: "",
        pdf: "",
    })

    const [certificates, setCertificates] = useState([]);
    
    const getCertificates = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/certificates?user=${user.Username}`);
        setCertificates([...await res.json()]);

    }
    const loadCertificate = async() => {
        setLoading(true);
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/certificates', {
            method: 'POST',
            body: JSON.stringify({...certificate, user: user.Username})
        })
        setLoading(false);
        window.location.reload();
        console.log(res);
    }
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
      };

    const [role, setRole] = useState({});
    const [nextRole, setNextRole] = useState({});

    const getNextRole = async() => {
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        const role = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
        setRole({...await role.json()});
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/next-role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        setNextRole({...await res.json()});
    }
    useEffect(() => {
        getCertificates();
        getNextRole();
    }, [])

    const [centriFormazione, setCentriFormazione] = React.useState([
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Ravenna'}, {title: 'Distanza', description: '0.2km'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Roma'}, {title: 'Distanza', description: '102.5km'}]
        },
        {
            icon: <Language fontSize="large" />,
            text: 'Centro formazione',
            widgets: [{title: 'Città', description: 'Napoli'}, {title: 'Distanza', description: '302.0km'}]
        },
    ])
    return (<div>
        <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2">
                        Carica Corso
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <input
                            type="file"
                            onChange={handleChange}
                            ref={hiddenFileInput}
                            style={{ display: "none" }} // Make the file input element invisible
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="id"
                            label="Numero"
                            name="id"
                            onChange={(e) => setCertificate({...certificate, number: e.target.value})}
                        />
                        <FormControl>  
                            <InputLabel id="demo-simple-select-helper-label">Tipologia certificato</InputLabel>

                            <Select
                                label="Tipologia certificato"
                                onChange={(e) => setCertificate({...certificate, category: e.target.value})}
                            >
                                <MenuItem></MenuItem>
                                <MenuItem value={'Antincendio'}>Antincendio</MenuItem>
                                <MenuItem value={'Sopravvivenza & Salvataggio'}>Sopravvivenza & Salvataggio</MenuItem>
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Data di Rilascio" onChange={(e) => setCertificate({...certificate, released: moment(e.toDate()).format('DD/MM/YYYY')})}/>
                            </DemoContainer>
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label="Data di scadenza" onChange={(e) => setCertificate({...certificate, expiry: moment(e.toDate()).format('DD/MM/YYYY')})}/>
                            </DemoContainer>
                        </LocalizationProvider>

                        <Button onClick={handleClick}>Carica il certificato</Button>

                        <LoadingButton variant="contained" onClick={() => loadCertificate()} loading={loading}>Salva</LoadingButton>

                    </Box>
                </Box>
        </Modal>
            <span style={{fontSize: '2rem', fontWeight: 600, color: '#3d4a71', marginBottom: 12, display: 'block'}}>I tuoi Corsi</span>
        {/* <Box elevation={3} sx={{width: '100%', height: 85, marginTop: '23px',  backgroundColor: '#fff', boxShadow: '0px -1px 6px #00000029', display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'center', paddingLeft: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Filtra &nbsp;<FilterList fontSize="16px"/></Typography>

            </div>
            <div style={{display: 'flex', alignItems: 'center', paddingRight: '28px'}}>
                <Typography color="primary" textTransform={'uppercase'} fontSize="16px" alignItems="center" display="flex">Ordina per &nbsp;<ExpandMore fontSize="16px"/></Typography>

            </div> 
        </Box>*/}
        <div style={style.container}>
            
            
            <div className="carousel" style={{display: 'flex', width: '100%', overflow: 'auto', padding: 26, transform: 'translateX(-26px)'}}>{
               certificates.length > 0 ? certificates.map((item) => (<>
                <Card icon={<DashboardCustomize fontSize="large" />} text={item.course['nome corso']} widgets={[{title: 'Conseguito', description: moment(item.release).format('DD/MM/YYYY')}, {title: 'Scade', description: moment(item.expiry).format('DD/MM/YYYY')}]} />
                </>
                )) : <div style={{width: '100%', textAlign: 'center'}}>
                    <p style={{textAlign: 'center', paddingBottom: 24, marginTop: 0}}>Non hai caricato nessun certificato. <br /> In questa sezione puoi caricare i tuoi certificati.</p>
                </div>
            }</div>
        </div>
        <CustomButton onClick={() => setModalOpen(true)} icon={<Add/>} text="Carica Corso" fontFamily="Spectral" inverse={true}/>
        
        {nextRole?.name && <>
            <Typography width={218} textTransform={'uppercase'} textAlign="center" justifyContent={"center"} margin="0 auto" marginTop="54px" color="#626262" fontFamily="Roboto">Scopri i requisiti per raggiungere il grado di</Typography>
            <Typography textTransform={'uppercase'} textAlign="center" justifyContent={"center"} margin="0 auto" marginTop="5px" color="#1B2B58" fontFamily="Spectral" fontSize="18px" fontWeight="bold">{nextRole.name}</Typography>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <CardCertificati 
            icon={<div style={{width: 95, height: 95}}><CircularProgressbar strokeWidth={5} styles={buildStyles({
                strokeLinecap: 'butt',
                textSize: '31px',
                pathTransitionDuration: 0.5,
                pathColor: `#1B2B58`,
                textColor: '#1B2B58',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
            })} value={nextRole.percentage} text={`${nextRole.percentage}%`}/></div>} 

            durability={`${nextRole.remainingMonths}`}
            description={`Di navigazione come ${role?.role ? role.role : null}`}
            certificates={
                nextRole.remainingCourses.map(x => ({name: x['nome corso'], registry: x['reg. stcw']}))
            }
            />
            </div>
        </>}
        
        
    </div>
    
    
    )
    
}

export default Dashboard;