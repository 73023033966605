const ServerConfig = {
    AWS: {
        secret: "l+H8boSjPkHCAuPVCN4RIDWR+3bK2CPdgGRavSlC",
        accessKey: "AKIAWBCKWXONIERTI7G3",
        s3Bucket: {
            Region: 'eu-west-1',
            BucketName: 'new-mase',
            ApiVersion: '2006-03-01',
            AccessKeyId: 'AKIAWBCKWXONLAGCSRK2',
            SecretKeyId: 'kAfPlH7igrCIIfHf2ScDGUEh+zOyAhuQwEOELVZl'
        },
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_NkcCk1o8P",
            ClientId: "23q2mbtk86chfqhnchvhghgf7v",
        }
    },
    authTimeout: 18000,
    platformName: 'MASE',
}

export { ServerConfig };