import { LoadingButton } from "@mui/lab";
import { Box, Button, MenuItem, Modal, Select, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useRef } from 'react';
import { AuthHelper } from '../helpers/AuthHelper';
import { ServerConfig } from '../connectors/Config';
import { config, S3 } from 'aws-sdk';
import moment from 'moment';
import { Buffer } from "buffer";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const ChangeIMO = (props) => {
    const [user, setUser] = React.useState([]);
    const [category, setCategory] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [profile, setProfile] = React.useState({});
    const [imo, setIMO] = React.useState("");
    const [matricola, setMatricola] = React.useState("");
    const [compartimento_marittimo, setCompartimento_marittimo] = React.useState("");
    const [role, setRole] = React.useState("");

    const [countNotifications, setCountNotifications] = React.useState(0);

    const getNotifications = async() => {
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/count/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        setCountNotifications(await res.json());
    }
    const getUser = async () => {
        
        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
        if(!_user)window.location.href="/login"
        sessionStorage.setItem('user', JSON.stringify(user));
        setUser([..._user])

        const getRole = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/role/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
        setRole((await getRole.json()).role);

        const getProfile = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/profile/${_user.filter(x => x.Name === 'sub')[0]?.Value}`);
        setProfile({...await getProfile.json()})
    }

    
    React.useEffect(() => {
        getUser();
        window.setInterval(() => {
            getNotifications();
        }, 10000);
        getNotifications();
    }, [])

    const getRolesByCategory = async() => {
        const getRoles = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/roles/${category}`)
        setRoles([...await getRoles.json()])
    }
    React.useEffect(() => {
        if(category) getRolesByCategory();
        
    }, [category]);

    const [loading, setLoading] = React.useState(false);
    const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };
    const handleChange = async (e) => {
        setLoading(true);

        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.imo.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            setIMO(`https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}`);
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const loadCertificate = async() => {
        if(!imo && roles.filter(x => x.id === selectedRole)[0]?.imo){
            alert("MANCA IL CERTIFICATO IMO");
            return;
        }
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/initiate/${user.Username}`, {
            method: 'PUT',
            body: JSON.stringify({role: selectedRole, IMO: imo, compartimento_marittimo, matricola})
        })
        setLoading(false);
        window.location.reload();
        console.log(res);
    }
    return (<Modal
        open={props.open}
        sx={{width: '100vw'}}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
            />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleziona ora il tuo grado
          </Typography>
          <Stack>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona categoria
            </Typography>
            <ToggleButtonGroup
                value={category}
                exclusive
                onChange={(e, nValue) => setCategory(nValue)}
                aria-label="text alignment"
                >
                <ToggleButton value="1" aria-label="left aligned">
                    1^
                </ToggleButton>
                <ToggleButton value="2" aria-label="centered">
                    2^
                </ToggleButton>
                <ToggleButton value="3" aria-label="right aligned">
                    3^
                </ToggleButton>
                {/**645dca28-6433-46bb-9767-6c86bf71dbba */}
            </ToggleButtonGroup>
            <Typography variant="caption" sx={{ mt: 2 }}>
                Seleziona ruolo
            </Typography>
            <Select onChange={(e, v) => setSelectedRole(e.target.value)} disabled={roles.length === 0}>
                {roles.sort((a, b) => a.name.localeCompare(b.name)).map((x) => (
                    <MenuItem value={x.id}>{x.name}</MenuItem>
                ))}
            </Select>
            
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Matricola"
                            placeholder="Matricola"
                            name="id"
                            onChange={(e) => setMatricola(e.target.value)}
                        />
            <TextField
                            margin="normal"
                            fullWidth
                            id="id"
                            label="Compartimento marittimo"
                            placeholder="Compartimento marittimo"
                            name="id"
                            onChange={(e) => setCompartimento_marittimo(e.target.value)}
                        />
            {!roles.filter(x => x.id === selectedRole)[0]?.name?.includes('Allievo') && roles.filter(x => x.id === selectedRole)[0]?.imo && <Button onClick={handleClick} variant="contained" sx={{mt: 2}} color="primary">Carica il certificato IMO</Button>}
            <LoadingButton variant="contained" color="primary" onClick={() => loadCertificate()} sx={{mt: 2}} loading={loading}>Salva</LoadingButton>

          </Stack>
          
        </Box>
      </Modal>);
}