import React, { useRef } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { AuthHelper } from '../helpers/AuthHelper';
import { Alert, Modal, TextField, Box, Typography, ToggleButton, ToggleButtonGroup, Stack, Select, MenuItem, Button } from '@mui/material';
import { ServerConfig } from '../connectors/Config';
import { config, S3 } from 'aws-sdk';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';
import { Buffer } from "buffer";
import Badge from '@mui/material/Badge';
import { Notifications } from '@mui/icons-material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const LoadIMO = (props) => {
    const [user, setUser] = React.useState([]);
    const [role, setRole] = React.useState("");
    const [open, setOpen] = React.useState(true);
    const [category, setCategory] = React.useState(null);
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [profile, setProfile] = React.useState({});
    const [imo, setIMO] = React.useState("");
    const [matricola, setMatricola] = React.useState("");
    const [compartimento_marittimo, setCompartimento_marittimo] = React.useState("");

    const [loading, setLoading] = React.useState(false);
    const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current?.click();
    };
    const handleChange = async (e) => {
        setLoading(true);
        config.update({
            Region: ServerConfig.AWS.s3Bucket.Region, 
            credentials: {
                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
            }});
        const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.imo.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            setIMO(`https://new-mase.s3.eu-west-1.amazonaws.com/${fileName}`);
            setLoading(false);
        }catch(ex){
            console.log(ex);
            alert('Errore server')
        }
    };
    const loadCertificate = async() => {
        if(imo === ""){
            alert("MANCA IL CERTIFICATO IMO");
            return;
        }
        const user = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/initiate/${user.Username}`, {
            method: 'PUT',
            body: JSON.stringify({role: props.role.id, IMO: imo})
        })
        localStorage.removeItem('examSeen');
        setLoading(false);
        window.location.reload();
        console.log(res);
    }
    return (<Modal
        open={open}
        sx={{width: '100vw'}}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }} // Make the file input element invisible
            />
          <Stack>
            <h2>Carica il certificato IMO per passare di grado!</h2>
            <Button onClick={handleClick} variant="contained" sx={{mt: 2}} color="primary">Carica il certificato IMO</Button>
            <LoadingButton variant="contained" color="primary" onClick={() => loadCertificate()} sx={{mt: 2}} loading={loading}>Salva</LoadingButton>
          </Stack>
          
        </Box>
      </Modal>)
}

export default LoadIMO;